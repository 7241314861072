// Code generated by Slice Machine. DO NOT EDIT.

import dynamic from 'next/dynamic';

export const components = {
  boxed_feature: dynamic(() => import('./BoxedFeature')),
  call_to_action: dynamic(() => import('./CallToAction')),
  centered_with_buttons: dynamic(() => import('./CenteredWithButtons')),
  counter: dynamic(() => import('./Counter')),
  featured_logos: dynamic(() => import('./FeaturedLogos')),
  featured_quote: dynamic(() => import('./FeaturedQuote')),
  hero: dynamic(() => import('./Hero')),
  image_with_text: dynamic(() => import('./ImageWithText')),
  infographic: dynamic(() => import('./Infographic')),
  link_group: dynamic(() => import('./LinkGroup')),
  list_right: dynamic(() => import('./ListRight')),
  listed_features: dynamic(() => import('./ListedFeatures')),
  navigation_item: dynamic(() => import('./NavigationItem')),
  quote_carousel: dynamic(() => import('./QuoteCarousel')),
  service_cards: dynamic(() => import('./ServiceCards')),
  steps: dynamic(() => import('./Steps')),
  team_section: dynamic(() => import('./TeamSection')),
};
